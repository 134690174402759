import { Suspense, lazy, useEffect } from "react";
import * as Sentry from "@sentry/react";
import TBLDotComConfig from "@tbl-dot-com/config";
import {
  Route,
  Routes,
  useLocation,
  BrowserRouter,
  useNavigate,
  Navigate,
  useParams,
} from "react-router-dom";
import clsx from "clsx";
import HeaderBar from "./layout/HeaderBar";
import NavFooter from "./layout/NavFooter";
import {
  AuthenticatedUser,
  AuthState,
  AWSAuthContextProvider,
  Credentials,
  useAuthContext,
} from "@tbl/aws-auth";
import { Container } from "semantic-ui-react";
import TBLMapmakerAppConfig from "./TBLMapmakerAppConfig";
import "./TBLDotComApp.scss";

// Currently needed for Semantic UI in Map Maker.
import "./semantic/semantic.min.css";

// We always import the home page in the root bundle since it is light and also the most  common
// landing page.
import CenteredLoader from "./components/CenteredLoader";
import { GlobalLayoutProvider } from "./layout/useLayout";
import { setNavigate } from "./TBLMapmakerAppConfig";
import HelpPage from "./pages/help/HelpPage";
import {
  MapmakerContextProvider,
  MapmakerPrivacyPolicyPage,
  MapmakerRefundPolicyPage,
  MapmakerTermsOfServicePage,
} from "@mapmaker/frontend";

// Bug tracking
Sentry.init({
  dsn:
    "https://dd392d0d2edf4939a4aebc5270aa2116@o450330.ingest.sentry.io/5434711",
  release: "tbl-dot-com@2022-11-18.0",
  environment: TBLDotComConfig.stage,
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
  ],
});

const HomePage = lazy(() => import("./pages/home/HomePage"));
const MapMakerRouter = lazy(() => import("./pages/mapmaker/MapmakerRouter"));
const RedeemStickerTokensPage = lazy(() =>
  import("./pages/mapmaker/RedeemStickerTokensPage")
);
const ShopRouter = lazy(() => import("./pages/shop/ShopRouter"));
const AccountRouter = lazy(() => import("./pages/account/AccountRouter"));
const DebugPage = lazy(() => import("./pages/debug/DebugPage"));
const BeginPage = lazy(() => import("./pages/instructions/begin/BeginPage"));
const InstructionsPage = lazy(() =>
  import("./pages/instructions/InstructionsPage")
);
const AboutPage = lazy(() => import("./pages/about/AboutPage"));
const ContactPage = lazy(() => import("./pages/contact/ContactPage"));
const GiftingGuidePage = lazy(() =>
  import("./pages/adhoc/gifting-guide/GiftingGuide")
);
const TikTokPromotionPage = lazy(() =>
  import("./pages/promotions/TikTokPromotionPage")
);
const InstagramPromotionPage = lazy(() =>
  import("./pages/promotions/InstagramPromotionPage")
);

function AppWithAuthContext() {
  return (
    <Sentry.ErrorBoundary>
      <AWSAuthContextProvider
        config={{
          identityPoolId: TBLDotComConfig.aws.cognito.identityPoolId,
          region: TBLDotComConfig.aws.region,
          userPoolId: TBLDotComConfig.aws.cognito.userPoolId,
          userPoolWebClientId: TBLDotComConfig.aws.cognito.appClientId,
        }}
      >
        <GlobalLayoutProvider>
          <AppWithContext />
        </GlobalLayoutProvider>
      </AWSAuthContextProvider>
    </Sentry.ErrorBoundary>
  );
}

async function logCredentialError(
  currentCredentials: () => Promise<Credentials>,
  user: AuthenticatedUser
) {
  console.log(
    `Unexpected Auth error.
    ${await currentCredentials()}
    ${user}`
  );
}

function AppWithContext() {
  const { state, user, identityId, currentCredentials } = useAuthContext();

  useEffect(() => {
    Sentry.setUser({
      id: identityId,
      email: user?.email,
    });
  }, [identityId, user]);

  if (state === AuthState.Initializing) {
    return null;
  } else if (state === AuthState.Error) {
    // If we bail here people won't have access to anything on the site, so let's just log it
    // for now.
    logCredentialError(currentCredentials, user);
  }

  return (
    <BrowserRouter>
      <MapmakerContextProvider
        appConfig={{ ...TBLMapmakerAppConfig }}
        identityId={identityId}
        currentCredentials={currentCredentials}
      >
        <App />
      </MapmakerContextProvider>
    </BrowserRouter>
  );
}

const FIXED_LAYOUT_PATHS = ["/mapmaker/"];
const visitedKeys: { [key: string]: boolean } = {};

function App() {
  // This is sketchy but our mapmaker app config needs access to the navigate object from React
  // Router so we set it here.
  const navigate = useNavigate();
  setNavigate(navigate);

  const location = useLocation();

  const fixedLayout = FIXED_LAYOUT_PATHS.some(path =>
    location.pathname.startsWith(path)
  );

  // This will scroll the page to the top for any new states pushed to the history. Going back to a
  // previous state will *not* restore the scroll which is desired on forward/back actions.
  useEffect(() => {
    if (location.key) {
      if (!visitedKeys[location.key]) {
        window.scrollTo(0, 0);
      }
      visitedKeys[location.key] = true;
    }
  }, [location.key]);

  return (
    <div
      className={clsx({
        layout: true,
        "fixed-layout": fixedLayout,
        "variable-layout": !fixedLayout,
      })}
    >
      <HeaderBar />
      <div className="page-container">
        <Suspense fallback={<CenteredLoader />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* Regular Pages */}
            <Route path="/about" element={<AboutPage />} />
            <Route path="account/*" element={<AccountRouter />} />
            <Route path="redeem" element={<RedeemStickerTokensPage />} />
            <Route
              path="/instructions-original"
              element={<InstructionsPage />}
            />
            <Route path="/photomaps/instructions" element={<BeginPage />} />
            <Route path="/instructions" element={<BeginPage />} />
            <Route path="/begin" element={<BeginPage />} />
            <Route path="help">
              <Route path="" element={<HelpPage />} />
              <Route path=":slug" element={<HelpPage />} />
            </Route>
            {/* Ad-hoc */}
            <Route path="/gift-guide" element={<GiftingGuidePage />} />
            {/* Promotions */}
            <Route path="/tiktok" element={<TikTokPromotionPage />} />
            <Route
              path="insta"
              element={<Navigate to="/instagram" replace />}
            />
            <Route path="ig" element={<Navigate to="/instagram" replace />} />
            <Route path="instagram" element={<InstagramPromotionPage />} />
            {/* We linked a lot of people to FAQ in the past */}
            <Route path="/faq" element={<Navigate to="help" />} />
            <Route
              path="jobs"
              element={<Navigate to="/contact/jobs" replace />}
            />
            <Route path="contact" element={<ContactPage />}>
              <Route path="" />
              <Route path=":slug" />
              <Route path="*" />
            </Route>
            <Route path="/debug" element={<DebugPage />} />
            <Route path="/shop/*" element={<ShopRouter />} />
            <Route path="/mapmaker/*" element={<MapMakerRouter />} />
            {/* Special Shopify policy pages */}
            <Route
              path="refunds"
              element={
                <Container>
                  <MapmakerRefundPolicyPage />
                </Container>
              }
            />
            <Route
              path="privacy"
              element={
                <Container>
                  <MapmakerPrivacyPolicyPage />
                </Container>
              }
            />
            <Route
              path="terms"
              element={
                <Container>
                  <MapmakerTermsOfServicePage />
                </Container>
              }
            />
            {/** We can't change our shopify default website, so we redirect to
             * /shopify-redirect/... for everything except the cart page. The product page is the
             * most important which we handle here. */}
            <Route
              path="shopify-redirect/shop/products/:handle"
              element={<ShopifyProductRouter />}
            />
            {/** Redirect so that the sandbox URL is the same on TBL and PM for convenience. */}
            <Route
              path="sandbox"
              element={<Navigate to="/mapmaker/sandbox" replace />}
            />
            {/* Catch-all to redirect home. Replace to avoid back-navigate redirect loop. */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </div>
      <NavFooter />
    </div>
  );
}

function ShopifyProductRouter() {
  const { handle = "" } = useParams();
  return <Navigate to={`/shop/product/${handle}`} replace={true} />;
}

export default AppWithAuthContext;
